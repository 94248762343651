<template>
<div>
  <q-page padding>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="FAQ" />
    </q-breadcrumbs>

<h5 class="q-my-md">
	Frequently Asked Questions
</h5>


<div class="q-mb-md">
<p>
</p>


<!--
<h6 class="q-my-none">Who runs {{name}}?</h6>
<p>
{{name}} is run by Joseph Levy located in Brooklyn, NY who has been running a boutique staffing agency for a number of years filling similar positions for Jewish community organizations.  If you have any questions or comments please feel free to reach out to us.
</p>
-->

<h6 class="q-my-none">How do I get the 'jobseeker' role or activate my account to apply to jobs?</h6>
<p>
You can eaily enable your account as a job candidate by clicking <strong>Job Candidate</strong> switch to <i>Show Job Candidate Profile and Resume Tools</i> found on your <router-link color="primary" to="/account/home">account home page</router-link>. You then need to submit your <strong>Job Candidate</strong> information and <strong>upload your resume</strong> to be able to apply for jobs.
</p>


<h6 class="q-my-none">How can I format my job post?</h6>
<p>
  The job posts support <strong>Markdown</strong> syntax, therefore, you can make bold text by adding 2 asterisks around text ( ** bold text ** ), create italic text with single asterisks ( * italic text * ), and many many other styles, including headers, ordered lists, bullet points, and more.  For a guide on basic syntax <a href="https://www.markdownguide.org/basic-syntax/">visit the MarkdownGuide.org</a>.
</p>

<h6 class="q-my-none">I forgot my password, how do I reset it?</h6>
<p>
Click <router-link color="primary" to="/auth/forgot">Forgot Password</router-link>, and we can send you a password reset link.
</p>


<h6 class="q-my-none">There is a job post that is for my organization but not in my account, how can I edit it?</h6>
<p>
<router-link color="primary" to="/support/contact">Contact Us</router-link>, and we can move it to your account.
</p>


<h6 class="q-my-none">How can I check if a coupon is still valid?</h6>
<p>
You can use our <router-link color="primary" to="/support/coupon">Coupon viewer tool</router-link>.
</p>


<h6 class="q-my-none">How do I unsubscribe from the newsletter and emails?</h6>
<p>
<router-link color="primary" to="/support/unsubscribe">Unsubscribe here</router-link>.
</p>

<h6 class="q-my-none">How do I get help for an issue not listed here?</h6>
<p>
<router-link color="primary" to="/support/contact">Contact Us</router-link>!
</p>

</div>




  </q-page>

</div>

</template>

<script>
export default {
  data() {
    return {
      name: 'DegreeJobs',
      support_email: 'support@degreejobs.com',
    }
  }
}
</script>
